<template>
  <app-page-wrapper
    class="mb-4 !max-w-[calc(theme(screens.3xl)+theme(spacing.24))] !px-4 sm:mb-6 sm:!px-6 md:mb-10 md:!px-10"
    tag="footer"
  >
    <div class="rounded-xl bg-primary-950 p-6 text-white md:p-12">
      <div
        class="grid gap-8 lg:grid-cols-[repeat(var(--columnCount),1fr)]"
        :style="{
          '--columnCount': sections.length + 1,
        }"
      >
        <div class="mr-8">
          <app-image
            alt="Pollen logo"
            class="mb-6 cursor-emoji-[🐝]"
            src="/images/logo_full_white.svg"
          />

          <p class="text-sm">
            Organisme de formation professionnelle et continue enregistré sous
            le numéro 11922636792.
          </p>

          <app-image
            alt="Qualiopi logo"
            class="my-4"
            src="/images/qualiopi_logo.svg"
          />

          <p class="text-sm">
            <a class="underline" :href="qualiopiCertificate" target="_blank"
              >La certification qualité</a
            >
            a été délivrée au titre de la catégorie d’actions suivante : ACTIONS
            DE FORMATION
          </p>

          <p class="my-4 flex items-center gap-2 text-sm">
            <a :href="linkedin" target="_blank">
              <app-icon icon="mdi:linkedin" />
            </a>
            <app-divider class="!h-4 border-white/50" vertical />
            contact@heypollen.com
          </p>
        </div>

        <section v-for="section in sections" :key="section.title">
          <h3 class="mb-4 text-xl font-bold">
            {{ section.title }}
          </h3>

          <ul class="grid gap-2">
            <li v-for="item in section.items" :key="item.label">
              <nuxt-link
                active-class="!opacity-100 font-semibold"
                class="block opacity-70 transition hover:translate-x-1 hover:opacity-100"
                :href="item.href"
                :target="item.href ? '_blank' : undefined"
                :to="item.to"
              >
                {{ item.label }}
              </nuxt-link>
            </li>
          </ul>
        </section>
      </div>

      <p class="col-start-1 col-end-[--columnCount] mt-16 text-center text-sm">
        {{ copyrightMention }}
      </p>
    </div>
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "@pollen/core/model/i18n.model";

import type { RouteLocationRaw } from "#vue-router";
import { useCopyrightMention } from "~/layouts/_includes/composables/main-footer.hook";

const copyrightMention = useCopyrightMention();
const {
  public: {
    apps: { jobs: jobsUrl },
    documents: { qualiopiCertificate, pressKit },
    socialMediasUrls: { linkedin },
  },
} = useRuntimeConfig();

const { data: tagPages } = useTagPageList();

const sections = computed<
  {
    title: I18nMessage;
    items: { label: I18nMessage; to?: RouteLocationRaw; href?: string }[];
  }[]
>(() => {
  return [
    {
      title: "Entreprises",
      items: [
        {
          label: "Offre entreprises",
          to: { name: "enterprise" },
        },
        {
          label: "Pollen Academy",
          to: { name: "academy" },
        },
        {
          label: "Nos formations",
          to: { name: "catalog-index-top-trainers" },
        },
      ],
    },
    {
      title: "Thématiques",
      items: tagPages.value.map((tagPage) => {
        return {
          label: `Formations ${tagPage.tag.label}`,
          to: tagPage._links.page,
        };
      }),
    },

    {
      title: "Ressources",
      items: [
        {
          label: "Blog",
          to: { name: "blog" },
        },
        {
          label: "Méthodologie",
          to: { name: "methodology" },
        },
        {
          label: "Financement par mon employeur",
          to: { name: "getting-financed" },
        },
        {
          label: "Qui sommes-nous ?",
          to: { name: "about-us" },
        },
        {
          label: "Rejoindre l'équipe",
          href: jobsUrl,
        },
        {
          label: "CGU & CGV",
          to: "/content/terms-and-conditions",
        },
        {
          label: "Politique de confidentialité",
          to: "/content/privacy-policy",
        },
        {
          label: "Mentions légales",
          to: "/content/legal-notices",
        },
        {
          label: "Règlement intérieur",
          to: "/content/rules-of-procedure",
        },
        {
          label: "Press & media kit",
          href: pressKit,
        },
      ],
    },
  ];
});
</script>
